














































import { defineComponent } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { APPLICATION_VERSION } from "@/lib/constants/env";
import { useCurrentGym } from "@/lib/hooks/useCurrentGym";
import { queries } from "@/lib/query/queries";

export default defineComponent({
	setup() {
		const { id: currentGymId } = useCurrentGym();
		const { data: gyms } = useQuery(queries.gym.all());

		return { APPLICATION_VERSION, currentGymId, gyms };
	},
});
